import { IMainMenuItem } from 'c4p-portal-util';

const MAIN_MENU_ITEMS: IMainMenuItem[] = [
  {
    name: 'general.menu.Dashboards',
    icon: 'equalizer',
    permissions: ['dashboard:view'],
    items: [
      {
        name: 'dashboard.titles.AllDashboards',
        route: 'routeWillBeLoadedFromDb',
        permission: 'dashboard:all:view',
        order: 20,
      },
      {
        name: 'dashboard.titles.MyClients',
        route: 'routeWillBeLoadedFromDb',
        permission: 'dashboard:myclients:view',
        order: 30,
      },
      {
        name: 'dashboard.titles.MyProductivity',
        route: 'routeWillBeLoadedFromDb',
        permission: 'dashboard:myproductivity:view',
        order: 40,
      },
      {
        name: 'dashboard.titles.TeamClients',
        route: 'routeWillBeLoadedFromDb',
        permission: 'dashboard:teamclients:view',
        order: 50,
      },
      {
        name: 'dashboard.titles.TeamClientsLocation',
        route: 'routeWillBeLoadedFromDb',
        permission: 'dashboard:teamlocation:view',
        order: 60,
      },
      {
        name: 'dashboard.titles.TeamProductivity',
        route: 'routeWillBeLoadedFromDb',
        permission: 'dashboard:teamproductivity:view',
        order: 70,
      },
      {
        name: 'dashboard.titles.TeamRevenue',
        route: 'routeWillBeLoadedFromDb',
        permission: 'dashboard:teamrevenue:view',
        order: 80,
      },
      {
        name: 'dashboard.titles.Management',
        route: 'willBeLoadedFromDb',
        permission: 'dashboard:management:view',
        order: 90,
      },
      {
        name: 'dashboard.titles.MyClientsAndHours',
        route: 'willBeLoadedFromDb',
        permission: 'dashboard:myclientshours:view',
        order: 100,
      },
      {
        name: 'dashboard.titles.ManagementClients',
        route: 'willBeLoadedFromDb',
        permission: 'dashboard:managementclients:view',
        order: 110,
      },
      {
        name: 'dashboard.titles.ManagementHours',
        route: 'willBeLoadedFromDb',
        permission: 'dashboard:managementhours:view',
        order: 120,
      },
      {
        name: 'dashboard.titles.CoordinatorDashboard',
        route: 'willBeLoadedFromDb',
        permission: 'dashboard:teamlead:view',
        order: 130,
      },
      {
        name: 'dashboard.titles.ApplicationsDashboard',
        route: 'willBeLoadedFromDb',
        permission: 'dashboards:applications:view',
        order: 130,
      },
      {
        name: 'dashboard.titles.ManagementMatches',
        route: 'willBeLoadedFromDb',
        permission: 'dashboard:managementmatches:view',
        order: 140,
      }
    ],
  },
  {
    name: 'general.menu.SystemAdministration',
    icon: 'settings',
    permissions: ['function:list', 'checklist:list', 'logbook:list'],
    items: [
      {
        name: 'general.menu.Configuration',
        route: 'configuration',
        permission: 'document:watermark:manage',
      },
      {
        name: 'SystemAdministration.menu.Functions',
        route: 'system-administration',
        permission: 'function:list',
      },
      {
        name: 'general.menu.Checklist',
        route: 'checklist',
        permission: 'checklist:list',
      },
      {
        name: 'SystemAdministration.menu.Logbook',
        route: 'logbook',
        permission: 'logbook:list',
      },
    ],
  },
  {
    name: 'general.menu.Hours',
    icon: 'schedule',
    permissions: [
      'hourregistration:view',
      'hourregistration:manage',
      'hourregistration:create',
    ],
    items: [
      {
        name: 'general.menu.Hours',
        route: 'hours/hours',
        permission:
          'hourregistration:view|hourregistration:manage|hourregistration:create',
        permissionOperator: 'or',
      },
      {
        name: 'hours.titles.AfasExport',
        route: 'hours/afas-exports',
        permission: 'hourregistration:AFASexport',
        permissionOperator: 'or',
      },
    ],
  },
  {
    name: 'general.menu.Billing',
    icon: 'money',
    permissions: [
      'conceptinvoiceline:view',
      'conceptinvoiceline:manage',
      'template:view',
      'template:manage',
      'processedinvoiceline:view',
      'processedinvoiceline:manage',
      'compileinvoiceline:view',
      'compileinvoiceline:manage',
      'invoice:view',
    ],
    items: [
      {
        name: 'billing.labels.ConceptInvoices',
        route: 'billing/concept-invoice',
        permission: 'conceptinvoiceline:view|conceptinvoiceline:manage',
        permissionOperator: 'or',
      },
      {
        name: 'billing.labels.ConceptTemplates',
        route: 'billing/concept-templates',
        permission: 'template:view|template:manage',
        permissionOperator: 'or',
      },
      {
        name: 'billing.titles.ReplacementLineGroups',
        route: 'billing/replacement-line-group',
        permission: 'invoice:manage',
        permissionOperator: 'or',
      },
      {
        name: 'billing.labels.DropoutList',
        route: 'billing/dropout-list',
        permission: 'conceptinvoiceline:manage',
        permissionOperator: 'or',
      },
      {
        name: 'billing.labels.RunLog',
        route: 'billing/run-log',
        permission: 'conceptinvoiceline:manage|processedinvoiceline:manage',
        permissionOperator: 'or',
      },
      {
        name: 'billing.labels.ManageInvoices',
        route: 'billing/manage-invoices',
        permission: 'invoice:view|invoice:manage',
        permissionOperator: 'or',
      },
      {
        name: 'billing.labels.AutoInvoiceManagement',
        route: 'billing/auto-invoice-management',
        permission: 'invoice:auto:template:manage',
        permissionOperator: 'or',
      },
    ],
  },
  {
    name: 'general.menu.Administration',
    icon: 'tune',
    permissions: [
      'employee:list',
      'team:list',
      'location:list',
      'employee:availability:manage',
      'participation:list',
      'project:list',
      'careprogram:zpm:view',
      'careprogram:zpm:full',
      'careprogram:zpm:carefile:full',
      'function:internalCostPrice:full',
      'assessment:fulloverview:view',
      'assessment:fulloverview:manage',
    ],
    items: [
      {
        name: 'employee.menu.Employees',
        route: 'employees',
        permission: 'employee:list',
      },
      {
        name: 'general.menu.Teams',
        route: 'teams',
        permission: 'team:list',
      },
      {
        name: 'general.menu.Availability',
        route: 'availability',
        permission: 'employee:availability:manage',
      },
      {
        name: 'general.menu.ParticipationOverview',
        route: 'participations',
        permission: 'participation:list',
      },
      {
        name: 'hours.titles.Locations',
        route: 'hours/locations',
        permission: 'location:list',
      },
      {
        name: 'general.menu.Projects',
        route: 'hours/projects',
        permission: 'project:list',
      },
      {
        name: 'careprogram.titles.Careprogram',
        route: 'care-program',
        permission: 'careprogram:zpm:view|careprogram:zpm:full',
        permissionOperator: 'or',
      },
      {
        name: 'groupconsults.titles.GroupConsults',
        route: 'group-consults',
        permission:
          'groupconsult:zpm:view|groupconsult:zpm:create|groupconsult:zpm:manage',
        permissionOperator: 'or',
      },
      {
        name: 'carefile.titles.Assessments',
        route: 'assessments',
        permission:
          'assessment:fulloverview:view | assessment:fulloverview:manage',
        permissionOperator: 'or',
      },
      {
        name: 'SystemAdministration.menu.CostPrice',
        route: 'cost-price-components',
        permission: 'function:internalCostPrice:full',
      },
    ],
  },
  {
    name: 'general.menu.FormatsDocuments',
    icon: 'assignment',
    permissions: [
      'format:list',
      'docgen:list',
      'documentCategory:list',
      'diarySubject:list',
      'socialarea:list',
      'report:topic:view',
    ],
    items: [
      {
        name: 'questionset.menu.Formats',
        route: 'formats',
        permission: 'format:list',
      },
      {
        name: 'general.menu.EHealth',
        route: 'e-health',
        permission: 'diarySubject:list',
      },
      {
        name: 'general.menu.SocialAreas',
        route: 'socialarea',
        permission: 'socialarea:list',
      },
      {
        name: 'document.labels.Categories',
        route: 'system-administration/document-categories',
        permission: 'documentCategory:list',
      },
      {
        name: 'general.menu.Topics',
        route: 'topics',
        permission: 'report:topic:view',
      },
      {
        name: 'general.menu.Docgen',
        route: 'docgen',
        permission: 'docgen:list',
      },
    ],
  },
  {
    name: 'general.menu.ProductCatalog',
    icon: 'apps',
    permissions: [
      'activity:list',
      'service:list',
      'internalProduct:list',
      'externalProduct:list',
      'commissioningCompany:list',
      'contract:list',
    ],
    items: [
      {
        name: 'productCatalog.titles.Activities',
        route: 'product-catalog/activities',
        permission: 'activity:list',
      },
      {
        name: 'productCatalog.titles.Services',
        route: 'product-catalog/services',
        permission: 'service:list',
      },
      {
        name: 'productCatalog.titles.InternalProducts',
        route: 'product-catalog/internal-products',
        permission: 'internalProduct:list',
      },
      {
        name: 'productCatalog.titles.ExternalProducts',
        route: 'product-catalog/external-products',
        permission: 'externalProduct:list',
      },
      {
        name: 'productCatalog.titles.CommissioningCompanies',
        route: 'product-catalog/commissioning-companies',
        permission: 'commissioningCompany:list',
      },
      {
        name: 'productCatalog.titles.Contracts',
        route: 'product-catalog/contracts',
        permission: 'contract:list',
      },
      {
        name: 'productCatalog.titles.NzaList',
        route: 'product-catalog/nza-list',
        permission: 'nzalist:manage',
      },
      {
        name: 'productCatalog.titles.TariffAndTrendsDashboard',
        route: 'product-catalog/tariff-and-trends-dashboard',
        permission: 'superset:manage',
        permissionOperator: 'or',
      },
    ],
  },

  {
    name: 'general.menu.Client',
    icon: 'group',
    permissions: [
      'client:list',
      'application:view',
      'application:full',
      'carefile:view',
      'carefile:global',
      'carefile:manage',
      'carefile:create',
    ],
    items: [
      {
        name: 'client.menu.Application',
        route: 'applications',
        permission: 'application:view|application:full',
        permissionOperator: 'or',
      },
      {
        name: 'client.menu.Clients',
        route: 'clients',
        permission: 'client:list',
      },
      {
        name: 'general.menu.Carefiles',
        route: 'carefiles',
        permission: 'carefile:view|carefile:global|carefile:manage',
        permissionOperator: 'or',
      },
    ],
  },
];

export { MAIN_MENU_ITEMS };
